
import { defineComponent, ref, Ref, onMounted, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { PlusOutlined, InfoCircleFilled } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { fileToBase64 } from "@hd2/common/src/utils";
import { useForm, useField } from "vee-validate";
import { isValidPesel, isValidPostcode } from "@hd2/common/src/utils";
import { Langs, RegisterPersonal } from "../../types";
import PhoneNumberComponent from "@hd2/common/src/components/PhoneNumber.vue";
import {
  OptionData,
  OptionCoreData,
} from "ant-design-vue/lib/vc-select/interface";
import { remove, map, isNil, get, set } from "lodash";
import { useStore } from "../store";
import { deepIterate } from "@hd2/common/src/utils";
import { useRouter } from "vue-router";
import { PhoneNumber } from "@hd2/common/types";
import { AxiosStatic } from "axios";

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originFileObj: any;
}

interface FileInfo {
  file: File;
  fileList: FileItem[];
}

type RegisterPersonalExt = Omit<RegisterPersonal, "phone"> & {
  phone: PhoneNumber;
};

export const RegisterPersonalComponent = defineComponent({
  components: {
    PlusOutlined,
    phoneNumber: PhoneNumberComponent,
    InfoCircleFilled,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();
    const store = useStore();
    const router = useRouter();

    const avatarFileList: Ref<FileItem[]> = ref([]);

    const differentMailingAddress = ref(false);

    const schema = {
      firstName(value: RegisterPersonalExt["firstName"]) {
        return value ? true : t("REGISTER.FIELD_REQUIRED");
      },
      lastName(value: RegisterPersonalExt["lastName"]) {
        return value ? true : t("REGISTER.FIELD_REQUIRED");
      },
      nativeLanguageId(value: RegisterPersonalExt["nativeLanguageId"]) {
        return !isNil(value) ? true : t("REGISTER.FIELD_REQUIRED");
      },
      pesel(value: RegisterPersonalExt["pesel"]) {
        if (!value) {
          return t("REGISTER.FIELD_REQUIRED");
        } else if (!isValidPesel(value)) {
          return t("REGISTER.PERSONAL.PESEL_INVALID");
        }
        return true;
      },
      phone(value: RegisterPersonalExt["phone"]) {
        if (!value) {
          return t("REGISTER.FIELD_REQUIRED");
        } else if (!value.number || !value.prefix) {
          return t("REGISTER.FIELD_REQUIRED");
        } else if (value.pattern && !value.number.match(value.pattern)) {
          return t("REGISTER.PERSONAL.PHONE_NUMBER_INVALID");
        }
        return true;
      },
      "address.home.street"(
        value: RegisterPersonalExt["address"]["home"]["street"]
      ) {
        return value ? true : t("REGISTER.FIELD_REQUIRED");
      },
      "address.home.houseNumber"(
        value: RegisterPersonalExt["address"]["home"]["houseNumber"]
      ) {
        return value ? true : t("REGISTER.FIELD_REQUIRED");
      },
      "address.home.postCode"(
        value: RegisterPersonalExt["address"]["home"]["houseNumber"]
      ) {
        if (!value) {
          return t("REGISTER.FIELD_REQUIRED");
        } else if (!isValidPostcode(value)) {
          return t("REGISTER.ADDRESS.POSTCODE_INVALID");
        }
        return true;
      },
      "address.home.city"(
        value: RegisterPersonalExt["address"]["home"]["city"]
      ) {
        return value ? true : t("REGISTER.FIELD_REQUIRED");
      },
      "address.mailing.street"(
        value: Required<RegisterPersonalExt["address"]>["mailing"]["street"]
      ) {
        return value || !differentMailingAddress.value
          ? true
          : t("REGISTER.FIELD_REQUIRED");
      },
      "address.mailing.houseNumber"(
        value: Required<
          RegisterPersonalExt["address"]
        >["mailing"]["houseNumber"]
      ) {
        return value || !differentMailingAddress.value
          ? true
          : t("REGISTER.FIELD_REQUIRED");
      },
      "address.mailing.postCode"(
        value: Required<RegisterPersonalExt["address"]>["mailing"]["postCode"]
      ) {
        if (differentMailingAddress.value) {
          if (!value) {
            return t("REGISTER.FIELD_REQUIRED");
          } else if (!isValidPostcode(value)) {
            return t("REGISTER.ADDRESS.POSTCODE_INVALID");
          }
        }

        return true;
      },
      "address.mailing.city"(
        value: Required<RegisterPersonal["address"]>["mailing"]["city"]
      ) {
        return value || !differentMailingAddress.value
          ? true
          : t("REGISTER.FIELD_REQUIRED");
      },
    };

    const { errors, meta, validate } = useForm({
      validationSchema: schema,
    });

    const model: Ref<RegisterPersonalExt> = ref({
      photo: "",
      firstName: useField<RegisterPersonalExt["firstName"]>(
        "firstName",
        undefined,
        {
          initialValue: store.state.user.firstName,
        }
      ).value,
      lastName: useField<RegisterPersonalExt["lastName"]>(
        "lastName",
        undefined,
        {
          initialValue: store.state.user.lastName,
        }
      ).value,
      pesel: useField<RegisterPersonalExt["pesel"]>("pesel").value,
      phone: useField<RegisterPersonalExt["phone"]>("phone").value,
      nativeLanguageId:
        useField<RegisterPersonalExt["nativeLanguageId"]>("nativeLanguageId")
          .value,
      foreignLanguageIds:
        useField<RegisterPersonalExt["foreignLanguageIds"]>(
          "foreignLanguageIds"
        ).value,
      address: {
        home: {
          street: useField<RegisterPersonalExt["address"]["home"]["street"]>(
            "address.home.street"
          ).value,
          houseNumber: useField<
            RegisterPersonalExt["address"]["home"]["houseNumber"]
          >("address.home.houseNumber").value,
          flatNumber: useField<
            RegisterPersonalExt["address"]["home"]["flatNumber"]
          >("address.home.flatNumber").value,
          postCode: useField<
            RegisterPersonalExt["address"]["home"]["postCode"]
          >("address.home.postCode").value,
          city: useField<RegisterPersonalExt["address"]["home"]["city"]>(
            "address.home.city"
          ).value,
        },
        mailing: {
          street: useField<
            Required<RegisterPersonalExt["address"]>["mailing"]["street"]
          >("address.mailing.street").value,
          houseNumber: useField<
            Required<RegisterPersonalExt["address"]>["mailing"]["houseNumber"]
          >("address.mailing.houseNumber").value,
          flatNumber: useField<
            Required<RegisterPersonalExt["address"]>["mailing"]["flatNumber"]
          >("address.mailing.flatNumber").value,
          postCode: useField<
            Required<RegisterPersonalExt["address"]>["mailing"]["postCode"]
          >("address.mailing.postCode").value,
          city: useField<
            Required<RegisterPersonalExt["address"]>["mailing"]["city"]
          >("address.mailing.city").value,
        },
        secondary: {
          street: useField<
            Required<RegisterPersonalExt["address"]>["secondary"]["street"]
          >("address.secondary.street").value,
          houseNumber: useField<
            Required<RegisterPersonalExt["address"]>["secondary"]["houseNumber"]
          >("address.secondary.houseNumber").value,
          flatNumber: useField<
            Required<RegisterPersonalExt["address"]>["secondary"]["flatNumber"]
          >("address.secondary.flatNumber").value,
          postCode: useField<
            Required<RegisterPersonalExt["address"]>["secondary"]["postCode"]
          >("address.secondary.postCode").value,
          city: useField<
            Required<RegisterPersonalExt["address"]>["secondary"]["city"]
          >("address.secondary.city").value,
        },
      },
    });

    const loading: Ref<boolean> = ref(true);
    const submitLoading: Ref<boolean> = ref(false);
    const nativeLangs: Ref<Langs> = ref({});
    const foreignLangs: Ref<Array<OptionCoreData>> = ref([]);
    const foreignTreeSelectKey: Ref<number> = ref(0);

    const parseForeignLangs = (data: Langs) => {
      return map(data, (value, key) => {
        return {
          title: t(`LANG.${key}`),
          value: value,
          key: key,
        };
      });
    };

    const onDifferentMailingAddressChange = async () => {
      await validate();
    };

    onMounted(async () => {
      try {
        nativeLangs.value = await http
          .get("v1/doctors/dictionary/languages")
          .then((res) => res.data);
        model.value.nativeLanguageId = nativeLangs.value["PL"];
        foreignLangs.value = parseForeignLangs({ ...nativeLangs.value });
        remove(foreignLangs.value, function (value) {
          return value.key === "PL";
        });
      } catch (e: any) {
        showErrors(e.response?.data);
      }
      try {
        if (store.state.user.registerStatus !== "PERSONAL") {
          const data = await http
            .get(`v1/doctors/${store.state.user.id}/register/personal`)
            .then((res): RegisterPersonal => res.data);

          if (data.address.mailing?.street) {
            differentMailingAddress.value = true;
          }
          deepIterate<RegisterPersonal>(data, (key) => {
            if (key[1] !== "secondary" || get(data, key)) {
              set(model.value, key, get(data, key));
            }
          });
        } else {
          model.value.foreignLanguageIds = [];
        }
      } finally {
        loading.value = false;
      }
    });

    const submit = async () => {
      const reqBody: RegisterPersonal = {
        photo: model.value.photo,
        firstName: model.value.firstName,
        lastName: model.value.lastName,
        pesel: model.value.pesel,
        phone: {
          number: model.value.phone.number,
          prefix: model.value.phone.prefix,
        },
        nativeLanguageId: model.value.nativeLanguageId,
        foreignLanguageIds: model.value.foreignLanguageIds,
        address: {
          home: model.value.address.home,
        },
      };
      if (differentMailingAddress.value && model.value.address.mailing) {
        reqBody.address.mailing = { ...model.value.address.mailing };
      }
      if (
        model.value.address.secondary?.street &&
        model.value.address.secondary?.houseNumber &&
        model.value.address.secondary?.postCode &&
        model.value.address.secondary?.city
      ) {
        reqBody.address.secondary = { ...model.value.address.secondary };
      }
      submitLoading.value = true;
      try {
        await http.put(
          `v1/doctors/${store.state.user.id}/register/personal`,
          reqBody
        );
        store.commit("setUser", {
          ...store.state.user,
          registerStatus: "CONTRACT",
        });
        router.push("/register/contract");
      } catch (e: any) {
        showErrors(e.response?.data);
      } finally {
        submitLoading.value = false;
      }
    };

    const handleChangeAvatar = async (info: FileInfo) => {
      model.value.photo = await fileToBase64(info.file);
    };

    const onChangeNativeLang = (nativeLangId: number, option: OptionData) => {
      const langIndexToDelete = model.value.foreignLanguageIds.findIndex(
        (langId: number) => langId == nativeLangId
      );
      if (langIndexToDelete >= 0) {
        model.value.foreignLanguageIds.splice(langIndexToDelete, 1);
      }
      foreignLangs.value = parseForeignLangs({ ...nativeLangs.value });
      remove(foreignLangs.value, function (value) {
        return value.key === option.key;
      });
      foreignTreeSelectKey.value++;
    };

    const beforeUploadAvatar = (file: FileItem) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(t("REGISTER.ONLY_FILE_TYPE", { files: "png, jpg" }));
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(t("REGISTER.SMALLER_THAN", { maxFileSize: "2MB" }));
      }
      return !isJpgOrPng || !isLt2M;
    };

    return {
      avatarFileList,
      foreignLangs,
      nativeLangs,
      errors,
      handleChangeAvatar,
      onChangeNativeLang,
      beforeUploadAvatar,
      submitLoading,
      t,
      foreignTreeSelectKey,
      differentMailingAddress,
      onDifferentMailingAddressChange,
      model,
      submit,
      isValid: computed(() => meta.value.valid),
      loading,
    };
  },
});
export default RegisterPersonalComponent;
